import {Action, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import axios from 'axios';
import {PlayerConfigSummary} from "@/model";

@Module({namespaced: true, name: 'ApplicationModule'})
export class ApplicationModule extends VuexModule {
    players: Array<PlayerConfigSummary> = [];
    selectedPlayer: PlayerConfigSummary = {} as PlayerConfigSummary;
    playerConfig: any = {}

    get hasSelectedPlayer() {
        return this.selectedPlayer && this.selectedPlayer.competition?.uuid
    }

    @MutationAction
    async loadApplicationPlayers() {
        const players: Array<PlayerConfigSummary> = (await axios.get(`/api/admin`)).data
        if (players) {
            return {
                players: players
            }
        } else {
            return {}
        }
    }

    @MutationAction
    async selectPlayer(player: PlayerConfigSummary) {
        const playerConfig = (await axios.get(`/api/admin/${player.id}`)).data
        return {
            selectedPlayer: player,
            playerConfig: playerConfig
        }
    }
    @MutationAction
    async deletePlayer(player: PlayerConfigSummary) {
        (await axios.delete(`/api/admin/${player.id}`)).data
        return {
            selectedPlayer: {},
            playerConfig: {}
        }
    }

    @MutationAction
    async updatePlayerConfig(request: UpdatePlayerConfigRequest) {
        const playerConfig = (await axios.put(`/api/admin/${request.player.id}`, request.config)).data
        return {
            playerConfig: playerConfig
        }
    }

    @Action
    async online() {
        (await axios.post(`/api/admin/${this.selectedPlayer.id}/online`, {})).data
        this.selectedPlayer.online = true
    }

    @Action
    async offline() {
        (await axios.post(`/api/admin/${this.selectedPlayer.id}/offline`, {})).data
        this.selectedPlayer.online = false
    }

    @Action
    async showAdvertisement() {
        (await axios.post(`/api/admin/${this.selectedPlayer.id}/advertising`, {})).data
        this.selectedPlayer.advertising = true
    }

    @Action
    async hideAdvertisement() {
        (await axios.delete(`/api/admin/${this.selectedPlayer.id}/advertising`)).data
        this.selectedPlayer.advertising = false
    }
}

export interface UpdatePlayerConfigRequest {
    player: PlayerConfigSummary;
    config: any;
}