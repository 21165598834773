
















































import {Component, Vue} from 'vue-property-decorator';
import {ApplicationModule} from "@/store/ApplicationModule";
import {getModule} from "vuex-module-decorators";
import Loading from "@/components/Loading.vue";
import PlayerListComponent from "@/components/PlayerListComponent.vue";
import PlayerDetailComponent from "@/components/PlayerDetailComponent.vue";

@Component({
  components: {
    PlayerDetailComponent,
    PlayerListComponent,
    Loading
  }
})
export default class App extends Vue {
  applicationState = getModule(ApplicationModule, this.$store)

  mounted() {
    this.loadState()
  }

  private loadState() {
    this.$loader.load(async () => {
      await this.applicationState.loadApplicationPlayers()
    }, "load-application")
  }
}
