










































import {Component, Vue} from 'vue-property-decorator';
import {getModule} from "vuex-module-decorators";
import {ApplicationModule} from "@/store/ApplicationModule";
import {PlayerConfigSummary} from "@/model";
import Loading from "@/components/Loading.vue";

@Component({
  components: {Loading}
})
export default class PlayerListComponent extends Vue {
  applicationState = getModule(ApplicationModule, this.$store)

  get players() {
    return this.applicationState.players
  }

  get hasPlayers() {
    return this.applicationState.players && this.applicationState.players.length > 0
  }

  isSelected(player: PlayerConfigSummary) {
    return this.applicationState.hasSelectedPlayer
        && this.applicationState.selectedPlayer.id == player.id
  }

  select(player: PlayerConfigSummary) {
    this.$loader.load(async () => {
      await this.applicationState.selectPlayer(player)
    },"load-playerconfig")
  }

  hasLayout(player: PlayerConfigSummary) {
    return player.metadataLayoutName
  }

  layoutName(player: PlayerConfigSummary) {
    switch (player.metadataLayoutName) {
      case "JUMPING_PARTICIPANT":
        return "J"
      case "DRESSAGE_PARTICIPANT":
        return "D"
      default:
        return "?"
    }
  }
}
