


























































import {Component, Vue, Watch} from 'vue-property-decorator';
import {getModule} from "vuex-module-decorators";
import {ApplicationModule} from "@/store/ApplicationModule";
import Loading from "@/components/Loading.vue";
import VJsoneditor from 'v-jsoneditor'

@Component({
  components: {Loading, VJsoneditor}
})
export default class PlayerDetailComponent extends Vue {
  applicationState = getModule(ApplicationModule, this.$store)
  json = {}

  mounted() {
    this.updateSelectedPlayer()
  }

  @Watch("applicationState.selectedPlayer")
  updateSelectedPlayer() {
    if (!this.applicationState.hasSelectedPlayer) {
      return;
    }
    this.json = this.applicationState.playerConfig
  }

  get selectedPlayer() {
    return this.applicationState.selectedPlayer
  }

  async online() {
    await this.applicationState.online()
    this.$toast.success("Player Online")
  }

  async offline() {
    await this.applicationState.offline()
    this.$toast.success("Player Offline")
  }

  async showAdvertisement() {
    await this.applicationState.showAdvertisement()
    this.$toast.success("Player Advertising show")
  }

  async hideAdvertisement() {
    await this.applicationState.hideAdvertisement()
    this.$toast.success("Player Advertising hide")
  }

  async deletePlayer() {
    await this.applicationState.deletePlayer(this.applicationState.selectedPlayer)
    this.$toast.success("Player Deleted")
  }

  async updatePlayerConfig() {
    this.json = await this.applicationState.updatePlayerConfig({
      player: this.applicationState.selectedPlayer,
      config: this.json
    })
    this.$toast.success("Player Config Updated")
  }

}
