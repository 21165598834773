import VueWait from 'vue-wait';
import Vue from "vue";

Vue.use(VueWait)

const wait = new VueWait({
    useVuex: true
})

Vue.$wait = wait;

export default wait
