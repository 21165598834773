import Vue from 'vue'
import App from './App.vue'
import store from './store'
import BootstrapVue, {BootstrapVueIcons} from "bootstrap-vue";
import './styles/index.scss'
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import wait from "./vue-wait";
import {Loader, LoaderPlugin} from "@/utils/Loader";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from "axios";
import VueMoment from "vue-moment";

Vue.config.productionTip = false

declare module 'vue/types/vue' {
    interface VueConstructor {
        $loader: Loader;
    }

    interface Vue {
        $loader: Loader;
    }
}
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(LoaderPlugin, {wait: wait})

Vue.use(VueMoment)

Vue.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true
});

axios.interceptors.response.use(
    res => res,
    err => {
        Vue.$toast.error("Oops, er ging iets fout met de communicatie. Probeer het later opnieuw.")
        throw new Error(err.response.data.message);
    }
)

new Vue({
    store,
    wait,
    render: h => h(App)
}).$mount('#app')
