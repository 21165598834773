import Vue from 'vue'
import Vuex from 'vuex'
import {ApplicationModule} from '@/store/ApplicationModule'
import {SettingsModule} from "@/store/SettingsModule";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex)

const vuexSettings = new VuexPersistence({
    key: 'eqify-player-admin',
    modules: ['SettingsModule']
})

export default new Vuex.Store({
    modules: {
        'ApplicationModule': ApplicationModule,
        'SettingsModule': SettingsModule
    },
    plugins: [
        vuexSettings.plugin
    ]
});
